import Vue from 'vue'
const states = Vue.observable({
  pageLimitsList: '',
  btnPermissions: null,
  user: null
})

const mutations = {
}

export default {
  states,
  mutations
}
